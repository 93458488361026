import { CircularProgress } from '@mui/material'
import React,{useEffect} from 'react'
import { useParams , useNavigate } from 'react-router';

import axios from 'axios';

axios.defaults.baseURL = "https://auth.gtoken.in/";

function PortalPage() {
    let { portalId } = useParams();
    const navigate = useNavigate();

    const setPortal = (portalId) =>{
        console.log("portal id is "+portalId);
        axios.post("/portal/verify",{portalId:portalId}).then(res=>{
            if(res.data.error){
                //setErrorMsg(res.data.message);
            } else {
                localStorage.setItem("portalId",portalId);
                localStorage.setItem("portalUrl",res.data.data.url);
                localStorage.setItem("portalTitle",res.data.data.title);
                window.location.reload();
            }
        }).catch(error=>{
            
        })
    }


    const getPortal =() =>{

        console.log(portalId )
        if(portalId != localStorage.portalId){
            setPortal(portalId);
        } else {
            if(localStorage.portalUrl != undefined) {
                //navigate("/auth/login")
                navigate("/customer-portal/1")
                //console.log(localStorage.portalUrl)
            }
        }

        /*
        if(localStorage.portalUrl != undefined) {
            //navigate("/auth/login")
            navigate("/customer-portal/1")
            //console.log(localStorage.portalUrl)
        } else {
            if(query.get("portalId")){
                setPortalViaQueryParms()
            }
        }
        */
    }
    useEffect(()=>{
        getPortal()
    },[])


    return (
        <div className="container">
            <div className="row mt-5">
                <div className="col-md-12 text-center">
                    <CircularProgress/> {portalId}
                </div>
            </div>
        </div>
    )
}

export default PortalPage