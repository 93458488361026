
import React, { useEffect, useState } from 'react'
import { Link} from 'react-router-dom'
import { useNavigate } from 'react-router';
import { Receipt } from '@mui/icons-material';


export default function Header() {

    const [businessDetails,setBusinessDetails] = useState(null)
    const navigate = useNavigate();

    useEffect(()=>{
        if (localStorage.getItem("businessDetails") !== null) {
            setBusinessDetails(JSON.parse(localStorage.businessDetails))
        }
    },[])
    
    //let tokenDetails = JSON.parse(localStorage.tokenDetails);

    

    return (
        <>  
            <div className="container-fluid shadow p-2 mb-3">
                    <div className="row">
                        <div className="col-2 text-center"></div>
                        <div className="col-8 text-center">
                            {businessDetails && 
                                <Link className="navbar-brand" to="/customer-portal/1">
                                    {businessDetails.logo ? <img src={businessDetails.logo} style={{maxHeight:70}} alt={businessDetails.title} /> : businessDetails.title} 
                                </Link>
                            }
                        </div>
                        <div className="col-2">
                            { localStorage.getItem("customerToken") !== null && <Link to="/token-details"><Receipt className="mt-1" style={{fontSize:36}} /></Link> }
                        </div>
                    </div>
                    
                    
            </div>
            {/*
            <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
                <div className="container-fluid">
                    
                    <Link className="navbar-brand" to="/">
                        {
                            businessDetails.logo ? <img src={process.env.REACT_APP_BASE_URL+"/uploads/images/"+businessDetails.logo} style={{maxHeight:50}} /> : businessDetails.title
                        }
                    </Link>
                    
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0" style={{fontSize:"18px"}}>
                            <li className="nav-item">
                                <NavLink className="nav-link active" aria-current="page" to="/"> { businessDetails.title}</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            */}
        
        </>
    )
}
