import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { startSession } from "./ams/redux/authStore";
import AppRoutes from "./ams/routes/AppRoutes";

function App() {


  const navigate = useNavigate()
  let location = useLocation();



  useEffect(()=>{
    if(localStorage.portalUrl === undefined){
        //navigate("/select-portal");
    } else {
        if (localStorage.getItem("customerToken") !== null) {
            //navigate("/token-details");
        }
    }
  },[])

  

  return (
    <div className="App">
      <AppRoutes/>
    </div>
  );
}

export default App;
