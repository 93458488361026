import React,{useState,useEffect} from 'react'
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';


axios.defaults.baseURL = "https://auth.gtoken.in/";

function useQuery(){
    const {search} = useLocation();
    return React.useMemo(()=>new URLSearchParams(search),[search]);
}

function SelectPortalPage() {
    let query = useQuery();
    const navigate = useNavigate();


    const setPortalViaQueryParms = () =>{
        console.log("portal id is "+query.get("portalId"));
        axios.post("/portal/verify",{portalId:query.get("portalId")}).then(res=>{
            if(res.data.error){
                //setErrorMsg(res.data.message);
            } else {
                localStorage.setItem("portalId",query.get("portalId"));
                localStorage.setItem("portalUrl",res.data.data.url);
                localStorage.setItem("portalTitle",res.data.data.title);
                window.location.reload();
            }
        }).catch(error=>{
            
        })
    }

    const getPortal =() =>{

        console.log(query.get("portalId")+"&&"+query.get("portalId")+"!="+localStorage.portalId )
        if(query.get("portalId") && query.get("portalId") != localStorage.portalId){
            setPortalViaQueryParms();
        } else {
            if(localStorage.portalUrl != undefined) {
                //navigate("/auth/login")
                navigate("/customer-portal/1")
                //console.log(localStorage.portalUrl)
            }
        }

        /*
        if(localStorage.portalUrl != undefined) {
            //navigate("/auth/login")
            navigate("/customer-portal/1")
            //console.log(localStorage.portalUrl)
        } else {
            if(query.get("portalId")){
                setPortalViaQueryParms()
            }
        }
        */
    }
    useEffect(()=>{
        getPortal()
    },[])


    return (
        <div className="login-bg overlay-bg">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12 d-flex justify-content-center">
                                    <span className="uniworldvision-logo"></span>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12 text-center">
                                    <CircularProgress/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectPortalPage