import React from 'react';
import io from 'socket.io-client'



let appName =  "local"//process.env.REACT_APP_SOCKET_NAME;

const api_url = localStorage.getItem("portalUrl");

let a = document.createElement('a');
a.href = api_url;
appName = a.hostname.replace("-api.gtoken.in","");

console.log("app Name", appName);


export const connectionUrl = "https://tms-notification.gtoken.in?app="+appName;
export const SocketContext = React.createContext();