import React from 'react'
import { Route, Routes } from 'react-router'
import Dashboard from '../dashboard/pages/Dashboard'
import BookingPortal from '../app/portal/pages/BookingPortal'
import CustomerPortal from '../app/portal/pages/CustomerPortal'
import QueuePage from '../app/portal/pages/QueuePage'
import CheckInWithEmail from '../app/portal/pages/CheckInWithEmail'
import TokenDetails from '../app/portal/pages/TokenDetails'
import CheckInWithSms from '../app/portal/pages/CheckInWithSms'
import SelectPortalPage from '../dashboard/pages/SelectPortalPage'
import CheckinControllerPage from '../app/portal/pages/CheckinControllerPage'
import PortalPage from '../dashboard/pages/PortalPage'





export default function AppRoutes() {
  return (
        <Routes>
            
            <Route path="/" element={ <SelectPortalPage/> } />

            <Route path="/portal/:portalId" element={ <PortalPage/> } />

            <Route path="/select-portal" element={ <SelectPortalPage/> } />

            {/* <Route path="/booking-portal/:businessId" element={ <BookingPortal /> } /> for appointment token */ }
            <Route path="/customer-portal/:businessId" element={  <CustomerPortal/> } />
            <Route path="/queue/:queueId" element={  <QueuePage/> } />
            { /* <Route path="/checkin/:businessId/:queueId/:serviceId" element={ <CheckInWithEmail/> } /> For Check in with Email  */ }
            
            <Route path="/checkin/:businessId/:queueId/:serviceId" element={ <CheckinControllerPage/> } />

            {/*<Route path="/checkin/:businessId/:queueId/:serviceId" element={ <CheckInWithSms/> } />*/}
            <Route path="/token-details" element={ <TokenDetails /> } />
        </Routes>
    )
}