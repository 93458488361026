import React, { useCallback, useContext, useEffect, useState } from 'react'
import { LinearProgress, Slide, StepContent } from '@mui/material';
import { useNavigate, useParams } from 'react-router';

import { Step, StepLabel, Stepper } from '@mui/material';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import OTPInput, { ResendOTP } from "otp-input-react";


import withAlert from '../../../hoc/withAlert';
import AppLayout from '../../../layout/components/AppLayout';
import portalServices from '../portalService';
import { SocketContext } from '../../../context/socket';
import io from 'socket.io-client'

function CheckInWithSms({queueDetails,AppAlert}) {
    let navigate = useNavigate();
    let {businessId, queueId,serviceId } = useParams();
    const [formValues, setFormValues] = useState(null);
    const [savingInProgress, setSavingStatus] = useState(false);

    const connectionUrl = useContext(SocketContext);

    const initialValues = {
        customerToken:"",
        firstName:"",
        phone:"",
    }


    const validationRule = Yup.object({});

    const [tokenValidationRule, setTokenValidationRule] = useState(null);

    const prepareValidationRue = () =>{
        let rules = {};
        console.log(queueDetails);
        
        if(queueDetails.otpEmail == 1){
            rules.email=Yup.string().email().required("Required");
        }

        //if(queueDetails.otpPhone == 1){
            rules.phone=Yup.string().required("Required").matches(/^[0-9]+$/, "Must be only digits").min(10, 'Invalid Phone Number').max(10, 'Invalid Phone Number');
        //}

        if(queueDetails.addressRequired == 1){
            rules.address=Yup.string().required("Required");
        }

        if(queueDetails.ageRequired == 1){
            rules.age=Yup.string().required("Required");
        }

        if(queueDetails.idProofRequired == 1){
            rules.idType=Yup.string().required("Required");
            rules.idProof=Yup.string().required("Required");
        }

        if(queueDetails.remarkRequired == 1){
            //rules.remark=Yup.string().required("Required");
        }

        let _tokenValidationRule = Yup.object(rules)
        setTokenValidationRule(_tokenValidationRule)
    }

    useEffect(()=>{
        prepareValidationRue();
    },[])


    /*  Stepper Work  Start*/
    const [activeStep,setActiveStep] = useState(0);

    const steps = [
        ["phone"],
        ["customerToken"],
        ["firstName" ],
    ]

    const validateNext = (formik) =>{
        let validationError = false;
        console.log(activeStep);
        steps[activeStep].forEach(field => {
            formik.setFieldTouched(field);
            formik.validateField(field);
            if(formik.errors && formik.errors[field] !== undefined){
                validationError = true;
            }
        });
        return validationError;
        
    }

    const handleNext = (formik) =>{
        if(!validateNext(formik) && activeStep < steps.length -1){
            setActiveStep(activeStep => ++activeStep)
        }
    }

    const handlePrevious = (formik) =>{
        if(activeStep > 0){
            setActiveStep(activeStep => --activeStep)
        }
    }

    /*  Stepper Work  End*/



    const onSubmit = (values, onSubmitProps) => {
        setSavingStatus(true);
        portalServices.doCheckin(values).then(res=>{
            //socket.emit("send_new_token",{message:"new token received"})
            socketEmit()
            AppAlert(res.msg);
            localStorage.setItem('customerToken',JSON.stringify(res.customerToken));
            navigate("/token-details");
            setOTPError("");
        }).catch(error=>{
            setOTPError(error.msg);
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    const socketEmit = () =>{
        const socket = io.connect(connectionUrl);
        socket.emit("send_new_token",{message:"new token received"})
        setTimeout(()=>{
            socket.close();
        },5000)
    }

    const [OTP, setOTP] = useState("");
    const [OTPError,setOTPError] = useState(null);

    const verifySmsOtp = (formik) =>{
            setSavingStatus(true);
            setOTPError(null)
            let data ={
                phone:formik.values.phone,
                businessId:businessId,
                queueId:queueId,
                serviceId:serviceId,
                otp:OTP
            };
            portalServices.verifySmsOTP(data).then(res=>{
                formik.setFieldValue("customerToken", res.data.token);
                setActiveStep(activeStep => ++activeStep)
                AppAlert("OTP Verified");
            }).catch(error=>{
                setOTPError(error.msg);
                AppAlert(error.msg,"error");
            }).finally(()=>{
                setSavingStatus(false);
            })

    }


    const requestSmsOTP = (formik) =>{
        if(!validateNext(formik) && activeStep < steps.length -1){
            setSavingStatus(true);
            console.log("phone is",formik.values.phone);
            let data ={
                phone:formik.values.phone,
                businessId:businessId,
            };
            portalServices.requestSmsOTP(data).then(res=>{
                setActiveStep(activeStep => ++activeStep)
                AppAlert("OTP Sent");
            }).catch(error=>{
                AppAlert(error.msg,"error");
            }).finally(()=>{
                setSavingStatus(false);
            })
            //
        }
    }

    const resendSmsOtp = (phone) =>{
        let data ={
            phone:phone,
            businessId:businessId,
        };
        portalServices.resendSmsOtp(data).then(res=>{
            AppAlert("OTP Sent");
        }).catch(error=>{
            AppAlert(error.msg,"error");
        })
    }

    const idTypeList = [
        {label:"NA", value:"0"},
        {label:"driving L", value:"1"},
        {label:"Aadhar card", value:"2"},
        {label:"Pan card", value:"3"},
        {label:"Employee Id", value:"4"},
        {label:"Voter Id", value:"5"},
        {label:"Passport Id", value:"6"},
    ]


    return (
        <AppLayout>
        {true && 
            <div className="container">
                <Slide direction="left" in={true} mountOnEnter unmountOnExit>
                    <div className="">
                        <div className="p-2">
                            <Formik
                                initialValues={formValues || initialValues}
                                validationSchema={tokenValidationRule || validationRule}
                                onSubmit={onSubmit}
                                enableReinitialize validateOnMount
                            >
                                {
                                    (formik) => {
                                        //console.log(formik.values);
                                        return (
                                            <Form>
                                                        <div className="row p-2">
                                                            <div className="col-md-12"> 
                                                                <Stepper activeStep={activeStep} orientation="vertical" >
                                                                    <Step>
                                                                        <StepLabel>Contact Details</StepLabel>
                                                                        <StepContent>
                                                                            {activeStep === 0 && 
                                                                                <>
                                                                                    <div className="row mt-4">
                                                                                        <div className="text-center">
                                                                                            <FormikControl control="input" formik={formik} type="text" 
                                                                                                label="Mobile Number" name="phone" 
                                                                                                className="col-md-6"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                        </StepContent>
                                                                    </Step>
                                                                    <Step>
                                                                        <StepLabel>Verify Contact Details</StepLabel>
                                                                        <StepContent>
                                                                            {activeStep === 1 && 
                                                                                <>
                                                                                <div className="row mt-4">
                                                                                    <div className="col-md-12 text-center">
                                                                                        <h6 className="text-success">We have sent an otp on <br/> {formik.values.phone}</h6>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row mt-2">
                                                                                    <div className="col-md-12 text-center">
                                                                                        <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={4} otpType="number" disabled={false} style={{display:"block"}}  />
                                                                                    </div>
                                                                                    <div className="col-md-12">
                                                                                        <button type="button" className="btn btn-link float-end" onClick={()=>resendSmsOtp(formik.values.phone)} >Resend OTP</button>
                                                                                    </div>
                                                                                    <div className="col-md-12 text-center">
                                                                                        {OTPError && <h4 className="mt-3 text-danger">{OTPError}</h4>}
                                                                                    </div>
                                                                                </div>

                                                                                </>
                                                                            }
                                                                        </StepContent>
                                                                    </Step>
                                                                    <Step>
                                                                        <StepLabel>Book My Appointment</StepLabel>
                                                                        <StepContent>
                                                                            {activeStep === 2 && 
                                                                                    <>
                                                                                    <div className="row mt-2">
                                                                                        <div className="col-md-12 text-center">
                                                                                            <h4>Your Details</h4>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row mt-2">
                                                                                        <div className="col-md-12 text-center">
                                                                                            <div className="row">
                                                                                                <div className="col-md-12">
                                                                                                    <FormikControl control="input" formik={formik} type="text" 
                                                                                                        label="First Name *" name="firstName"
                                                                                                    />
                                                                                                </div>
                                                                                                
                                                                                                {queueDetails.otpEmail == 1 && 
                                                                                                <FormikControl control="input" formik={formik} type="email" 
                                                                                                    label="Email*" name="email" 
                                                                                                    className="col-md-12"
                                                                                                />
                                                                                                }

                                                                                                {queueDetails.ageRequired == 1 && 
                                                                                                <FormikControl control="input" formik={formik} type="number" 
                                                                                                    label="Age*" name="age"
                                                                                                    className="col-md-12 "
                                                                                                />
                                                                                                }

                                                                                                {queueDetails.idProofRequired == 1 && 
                                                                                                
                                                                                                    <div className="col-md-12">
                                                                                                        <div className="row mt-4">
                                                                                                            <div className="col-md-6">
                                                                                                                <FormikControl control="autoselect" formik={formik} 
                                                                                                                    label="ID Type" name="idType"  
                                                                                                                    options={idTypeList} multiple={false} 
                                                                                                                />
                                                                                                            </div>

                                                                                                            <FormikControl control="input" formik={formik} type="text" 
                                                                                                                label="Id Proff Details" name="idProof"
                                                                                                                className="col-md-6"
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }

                                                                                                {queueDetails.addressRequired == 1 && 
                                                                                                <FormikControl control="input" formik={formik} type="text" 
                                                                                                    label="Address" name="address"
                                                                                                    className="col-md-12"
                                                                                                />
                                                                                                }

                                                                                                {queueDetails.remarkRequired == 1 && 
                                                                                                <FormikControl control="input" formik={formik} type="text" 
                                                                                                    label="Remark" name="remark"
                                                                                                    className="col-md-12"
                                                                                                />
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    </>
                                                                                }
                                                                        </StepContent>
                                                                    </Step>
                                                                </Stepper>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                        {  /* JSON.stringify(formik.values) */ }
                                                        </div>
                
                                                    
                                                
                                                
                                                    {!savingInProgress && 
                                                        <>
                                                            <div className="col-md-12">
                                                                <button className="btn  float-end" type="button" >Cancel</button>
                                                                {activeStep > 0 && <button type="button" className="btn btn-primary float-start" onClick={() => handlePrevious()}>Back </button> }
                                                                {activeStep === 0 &&
                                                                     <button type="button" className="btn btn-primary float-end" onClick={() => requestSmsOTP(formik)}>Continue </button>
                                                                }
                                                                {/* activeStep < steps.length -1 && activeStep !== 1 && <button type="button" className="btn btn-primary float-end" onClick={() => handleNext (formik)}>Next </button> */}
                                                                {activeStep === 1 && <button disabled={OTP.length < 4} type="button" className="btn btn-primary float-end" onClick={() => verifySmsOtp(formik)}>VerifyOtp </button> }
                                                                {activeStep === steps.length -1  &&
                                                                    <button className="btn btn-success float-end" type="submit" disabled={!formik.isValid} >Check In </button>
                                                                }
                                                            </div>
                                                        </>
                                                    }

                                                    {savingInProgress && <LinearProgress/>}

                                                    
                                                
                                            </Form>
                                        )
                                    }
                                }
                            </Formik>
                        </div>
                    </div>
                </Slide>
            </div>
        }
        </AppLayout>
    )
}

export default withAlert(CheckInWithSms)